import React from "react";
import { InsiderIconWithGreyBg } from "../../components/ui/icons/InsiderIconWithGreyBg";
import { Music } from "../../components/ui/icons/CategoryIcons/Music";
/**
 * @type {Link}
 * @property {string} resource_type - The type of resource, possible values are: "tag", "event", "article", "category", "external", "group", "go" and "filtered_list"
 * @property {string} [title] - The title of the link.
 * @property {string} [slug] - The slug for the link.
 * @property {string} [url] - The URL of the link (applicable for "external" resource_type).
 * @property {Object} [query_params] - Additional query parameters for the link (applicable for "filtered_list" resource_type).
 */
/**
 * @type {IIconCard}
 * @property {string} title - The title of the quick link.
 * @property {string} subtitle - The subtitle associated with the quick link.
 * @property {string} image - The URL of the image associated with the quick link.
 * @property {string[]} [includeCities] - An array of cities to include for the quick link. Add ["global"] for adding it in all cities. includeCities takes priority over excludeCities
 * @property {string[]} [excludeCities] - An array of cities to exclude for the quick link.
 * @property {Link} link - The object containing params to construct the links
 * @property {boolean} openInNewTab - Boolean set to true to open links in new tab
 */
/**
 * Configuration for the homepage UI components.
 * @type {IIconCard[]}
 */
// this is a temporary config to power the homepage ui components untill the admin flow is built
// note: if more than 3 quicklinks qualify for a city, they will be shuffled
export const quickLinks = [
    //Mumbai
    {
        title: "Boombox Season 3",
        subtitle: "Mumbai, Feb 1st!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1736664801/n5jh3omunadzsf1ckc7e.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "royal-stag-boombox-season-3-i-navi-mumbai-feb1-2025"
        }
    },
    {
        title: "Spoken Fest \'25",
        subtitle: "In Mumbai, this Feb",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737269963/do3okksfpa18hoc9psaw.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "spokenfest--mumbai-2025"
        }
    },
    {
        title: "The Lil Flea 2025",
        subtitle: "at Jio World Garden",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1736665245/n0ufhtjd8rwlzi2qahd0.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "the-lil-flea-mumbai-2025"
        }
    },
    //Delhi
    {
        title: "21 Savage LIVE",
        subtitle: "On Jan 31st!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894448/kh2lcv9uxblsjeyjup6o.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "indian-sneaker-festival-jan31-feb2-2025"
        }
    },
    {
        title: "Mughal E Azam",
        subtitle: "The Musical!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1735626793/jqdj9xq8ibw813xarca0.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "mughal-e-azam-delhi-feb13-2025"
        }
    },
    {
        title: "Boombox Season 3",
        subtitle: "Gurugram, Feb 8th!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1736664926/m6xbbatcujdiuirsv4oc.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "royal-stag-boombox-season-3-i-gurugram-feb8-2025"
        }
    },
    //Bengaluru
    {
        title: "LIVE in concert",
        subtitle: "Vineeth Sreenivasan",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734790697/lwgmldt1wdztw7s1imm2.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "vineet-sreenivasan-live-in-concert-feb1-2025"
        }
    },
    {
        title: "Russell Howard",
        subtitle: "LIVE in Bengaluru",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737269988/ivte1ztex4pst8c0z2jk.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "russell-howard-live-bengaluru-feb9-2025"
        }
    },
    {
        title: "ICW Bengaluru",
        subtitle: "Cocktails, Music & more",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1731736115/zh2gz0cjehegmhanmahm.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "india-cocktail-week-bengaluru-2025"
        }
    },
    //Pune
    {
        title: "Comic Con Pune",
        subtitle: "March 28th 2025",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734791162/nbikqz3iwvysciecrqso.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "pune-comic-con-2025-mar8-2025"
        }
    },
    {
        title: "Da Vinci Live",
        subtitle: "Immersive Art Journey",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732292229/pwzqfoo1xnlsgqfylplx.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "da-vinci-genius-an-interactive-immersive-experience-mumbai-nov30-2024"
        }
    },
    {
        title: "OG Rap King!",
        subtitle: "Yo Yo Honey Singh",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737270411/ixidrpmrtsppinf9b5qk.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-pune-mar14-2025"
        }
    },
    //Goa
    {
        title: "Hilltop Goa",
        subtitle: "Back to the Source!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1735627013/deuksi9hoyp5ylybve0i.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "hilltop-goa-presents-back-to-the-source-jan24-2025"
        }
    },
    {
        title: "Goa Socials Experience",
        subtitle: "At Echo on Feb 4th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1736665517/gig6zhhbtezc93idxu4n.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "goa-socials-experience-jan25-2025"
        }
    },
    {
        title: "Beach Fest in Goa",
        subtitle: "Sandunes Beach Fest, Jan 24",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685334/go6jee2t0jsi3w1knv42.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "sandunes-beach-fest-at-salud-goa-republic-edition-jan24-2025"
        }
    },
    //Hyderabad
    {
        title: "Boombox Season 3",
        subtitle: "Hyderabad, 25th Jan!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1736664926/m6xbbatcujdiuirsv4oc.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "royal-stag-boombox-season-3-i-hyderabad-jan25-2025"
        }
    },
    {
        title: "Fun Trio",
        subtitle: "Kids, Pets, Food!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737271305/ueprzghiyjzf8zjjkpts.png",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "hyderabad-kids-fair-and-petex-india"
        }
    },
    {
        title: "Sid Sriram",
        subtitle: "LIVE in Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509654/g2mieghbtpvyxioij7bq.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "sid-sriram-live-in-concert-hyderabad-feb15-2025"
        }
    },
    //Chennai
    {
        title: "Comic Con 2025",
        subtitle: "In Chennai, Feb 8!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1735627591/ddklhrzrtlggc2rauram.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "chennai-comic-con-2024-feb8-2025"
        }
    },
    {
        title: "All Hearts Tour",
        subtitle: "Shreya Ghoshal LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732292802/y8leuuxuykqhmzxu8hcs.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "shreya-ghoshal-live-all-hearts-tour-chennai-mar1-2025"
        }
    },
    {
        title: "Deva LIVE!",
        subtitle: "In Chennai on Feb 15",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1735635949/rm0r3ecttiqo354yjuwc.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-feb15-2025"
        }
    },
    //Kolkata
    {
        title: "Fossils LIVE",
        subtitle: "Ft. Nandi Sisters",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737270216/xd4rqgdkf3afagr28g42.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "fossils-live-nandy-sisters-kolkata-artist-jam-feb1-2025"
        }
    },
    {
        title: "Comic Con '25",
        subtitle: "In Kolkata this Feb!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1735627411/bkyjkpmtktsau6hv5dpl.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "kolkata-comic-con-2025-feb22-2025"
        }
    },
    {
        title: "KING is here!",
        subtitle: "In Kolkata, Jan 25",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737270191/xztrm0jfbappolcekorm.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "casa-bacardi-on-tour-ft-king-kolkata-2025"
        }
    },
    //Chandigarh
    {
        title: "Yo Yo Honey Singh Live",
        subtitle: "On 23rd March",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685223/kjnvyg7tnszzca6yytio.jpg",
        includeCities: ["Chandigarh"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-chandigarh-mar23-2025"
        }
    },
    {
        title: "Mughal E Azam",
        subtitle: "The Musical!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1735626793/jqdj9xq8ibw813xarca0.jpg",
        includeCities: ["Chandigarh"],
        link: {
            resource_type: "event",
            slug: "mughal-e-azam-delhi-feb13-2025"
        }
    },
    {
        title: "21 Savage LIVE",
        subtitle: "On Jan 31st!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732894448/kh2lcv9uxblsjeyjup6o.jpg",
        includeCities: ["Chandigarh"],
        link: {
            resource_type: "event",
            slug: "indian-sneaker-festival-jan31-feb2-2025"
        }
    },
    //Jaipur
    {
        title: "Yo Yo Honey Singh Live",
        subtitle: "on 29th March in Jaipur",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685223/kjnvyg7tnszzca6yytio.jpg",
        includeCities: ["Jaipur"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-jaipur-march29-2025"
        }
    },
    {
        title: "Mughal E Azam",
        subtitle: "The Musical!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1735636056/bdedu9hpa30ne1lsnkav.jpg",
        includeCities: ["Jaipur"],
        link: {
            resource_type: "event",
            slug: "mughal-e-azam-delhi-feb13-2025"
        }
    },
    {
        title: "Voodoo Jungle Fest",
        subtitle: "In Jaipur on 14 Feb",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685397/x019dcdjmv9jy4cazq6j.jpg",
        includeCities: ["Jaipur"],
        link: {
            resource_type: "event",
            slug: "voodoo-jungle-feb14-2025"
        }
    },
    //Indore
    {
        title: "Talwiinder LIVE",
        subtitle: "In Indore, Feb 8",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737270365/q32kirsajbhktmmjlglf.jpg",
        includeCities: ["Indore"],
        link: {
            resource_type: "event",
            slug: "oddone-live-presents-talwiinder-indore-feb8-2025"
        }
    },
    {
        title: "Cultural Sounds",
        subtitle: "On Feb 1, Wardha",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737270389/gzvw6aa7lxaybfbunzq8.jpg",
        includeCities: ["Indore"],
        link: {
            resource_type: "event",
            slug: "rishiraj-kulkarni-india-tour-handpan-rav-vast-tabla-flute-more-jan26-2025"
        }
    },
    {
        title: "OG Rap King!",
        subtitle: "Yo Yo Honey Singh",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737270411/ixidrpmrtsppinf9b5qk.jpg",
        includeCities: ["Indore"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-indore-mar8-2025"
        }
    }
];
export const placeholderQuickLinks = [
    {
        title: "Host an Event",
        subtitle: "Make your event live in under 5 mins",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "/list-your-events"
        }
    },
    {
        title: "Events this weekend",
        subtitle: "Events this weekend",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "everything",
            time_filter: "weekend"
        }
    }
];
export const mobilePlaceHolderLinks = [
    {
        title: "Download our app now!",
        subtitle: "Android & iOS",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "https://link.insider.in/app"
        }
    },
    ...placeholderQuickLinks
];
/**
     Key to be added for iOs on categoryBanners array:
     * hideForIOS : true if we don't want to show banner on iOs devices
     * showForIOS : true if we want to to show a different banenr for iOs device, create another object named categoryBannersForIOs with same format
     * * don't make both true
     * * No need to add above keys on categoryBannersForIOs, adding keys (if required) on original config array is sufficient.
     * * Don't add any key if not required
     */
export const categoryBannersForIOs = [];
export const categoryBanners = [
    //Category Banner
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736664679/kyacretfotqazjukyxtq.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736664679/ps3aehvp2l86utc2gvop.jpg",
        includeCities: ["mumbai"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-mumbai-feb22-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737271389/tavkf73krt9d48xfi5iu.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737271407/yzajm4feacqxel8sdtze.jpg",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "arijit-singh-india-tour-gurugram-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685653/xmdzggafyeuhivwvuej2.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685641/otenmvikg11utepdoj2q.jpg",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-bangalore-mar22-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685703/dqajugmpnytfg0iyg39x.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685705/qqxrjaqnfm4bpxd6zllf.jpg",
        includeCities: ["pune"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-pune-mar14-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737271441/vvsvvp2vy1nq4dxwnpzz.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737271443/xtxtr8xw9vrqedh7nl1z.jpg",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "royal-stag-boombox-season-3-i-hyderabad-jan25-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685747/pypl9lrtswss7yvaie76.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685748/cwhvcftmp77llmtrsq3m.jpg",
        includeCities: ["goa"],
        link: {
            resource_type: "event",
            slug: "india-international-music-week-echo-feb4-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685927/mofem9wnk7ag7fpjvzxt.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685927/sqimn5fwqyuwzimhggnz.jpg",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-kolkata-april5-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685986/fsunvqbnsst1vrfcxvqi.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685986/hhvzki69nbnohxu97aqa.jpg",
        includeCities: ["chandigarh"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-chandigarh-mar23-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686051/eafhnybs97mo2rmfehab.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686050/bbvsyw141zrl7vbhtano.jpg",
        includeCities: ["jaipur"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-jaipur-march29-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686092/mrhkpmatpvbruibkbbv1.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686092/setlefiulqzgzr2t0bry.jpg",
        includeCities: ["indore"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-indore-mar8-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686129/atdil3on8fwj2rzcxo2y.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686129/zayfcqnw4nqwu7ic8yda.jpg",
        includeCities: ["lucknow"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-lucknow-feb28-2025"
        }
    }
];
export const showcase = [
    //Type A
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737032028/go61n5ieyeltvcuoawid.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737031991/jcfcv9kxgbj1dnkjx1dv.jpg",
        //categoryIcon: <Music />,
        heading: "It's the finale for Ind vs Eng!",
        subheading: "Get notified when tickets are live.",
        caption: "Big hits, bigger moments, and the epic finale! Don’t miss India vs England at Wankhede. Get notified for tickets!",
        cta: "Know More",
        includeCities: ["mumbai"],
        link: {
            resource_type: "event",
            slug: "idfc-first-bank-series-5th-t20i-india-vs-england-mumbai"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731996140/e1ngi3bmybm8d7at9ys8.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732175414/lu3sd63xqnv0xcs0gy1d.jpg",
        categoryIcon: <Music />,
        heading: "Arijit Singh - LIVE in Concert",
        subheading: "The Voice of the generation makes a much awaited comeback to the stage.",
        caption: "After two sold out cities, Arijit Singh’s India tour comes to yours! Get ready for an unforgettable musical journey with India’s most beloved voice.",
        cta: "Buy Now",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "arijit-singh-india-tour-gurugram-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736687392/a2f302q5jdphvsbgktsi.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736687392/td7dl6kycur5at31bt1z.jpg",
        categoryIcon: <Music />,
        heading: "Yo Yo Honey Singh LIVE",
        subheading: "The OG Hitmaker’s back on tour!",
        caption: "Get ready for a night of legendary hits as Yo Yo Honey Singh sets the stage on fire! From Brown Rang to Love Dose, vibe to the bangers that redefined Indian music.",
        cta: "Book Now",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "external",
            url: "millionaire-india-tour-yo-yo-honey-singh-live-bangalore-mar22-2025/event"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736851018/lde8spvksfjrh3fipflv.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736851018/dscf67s20v8zztjlfbpz.jpg",
        categoryIcon: <Music />,
        heading: "Ind vs Eng T20I in Pune!",
        subheading: "A clash of the titans, watch it from the stadium!",
        caption: "The 4th T20I is heading to Pune, and every ball promises drama. Can India’s batting heroes take charge? Or will England’s bowlers flip the script? Get notified for ticket updates.",
        cta: "Know More",
        includeCities: ["pune"],
        link: {
            resource_type: "event",
            slug: "idfc-first-bank-series-4th-t20i-india-vs-england-pune"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686654/woxj81jnp6lcy3s79sge.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686654/dzujcqibtcwz42pbw8lo.jpg",
        categoryIcon: <Music />,
        heading: "IIMW Live, Goa 2025",
        subheading: "Music festival meets a global showcase—don’t miss it!",
        caption: "From homegrown stars to global sensations, IIMW Live is bringing 30+ artists together in Goa for a one-of-a-kind festival. Be part of this cultural revolution",
        cta: "Buy Now",
        includeCities: ["goa"],
        link: {
            resource_type: "event",
            slug: "india-international-music-week-echo-feb4-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1735628745/fskx2zco0axygh2ithau.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1735628832/caxuwsnfehwhnuovrht0.jpg",
        categoryIcon: <Music />,
        heading: "Sid Sriram Live!",
        subheading: "Join Sid Sriram in Hyderabad for a soulful performance where melody transcends boundaries.",
        caption: "On February 15th, experience Sid Sriram perform with his unique blend of Chennai roots & global sounds!",
        cta: "Buy Now",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "sid-sriram-live-in-concert-hyderabad-feb15-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736423301/hhmoxshgcabgktlmayxm.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736428209/odvki8lj4d86mesbbfya.jpg",
        heading: "An epic battle awaits!",
        subheading: "Tickets available now.",
        caption: "Get ready for an epic 2nd T20 clash at the iconic M.A. Chidambaram Stadium! 🔥🏏 Gather your squad, grab your jerseys, paint your faces, and bring the energy!",
        cta: "Buy Now",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "idfc-first-bank-series-2nd-t20i-india-vs-england-chennai"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1735628996/dt9ujwv40443c56mxf6i.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1735629057/suvsq5t0y62pm2arp2hb.jpg",
        categoryIcon: <Music />,
        heading: "King Live in Kolkata!",
        subheading: "Get ready for the BIGGEST party in town with Bacardi and KING live!",
        caption: "Bacardi Experiences presents #CasaBacardiOnTour with KING LIVE in Kolkata on Jan 25! If “Maan Meri Jaan” hits the right note, this is a vibe you can’t miss.",
        cta: "Buy Now",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "casa-bacardi-on-tour-ft-king-kolkata-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734600935/wx64dwheikrd4pphafj5.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734600935/sbsum04y34h42ger3i4z.jpg",
        categoryIcon: <Music />,
        heading: "Arijit Singh Live Tour",
        subheading: "Experience India's most beloved voice live!",
        caption: 'Get ready to witness Arijit Singh perform timeless hits like "Tum Hi Ho," "Kesariya," and "Channa Mereya" in an unforgettable live experience!',
        cta: "Buy Now",
        includeCities: ["chandigarh"],
        link: {
            resource_type: "event",
            slug: "arijit-singh-live-in-concert-chandigarh-feb16-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734600935/wx64dwheikrd4pphafj5.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734600935/sbsum04y34h42ger3i4z.jpg",
        categoryIcon: <Music />,
        heading: "Arijit Singh Live Tour",
        subheading: "Experience India's most beloved voice live!",
        caption: 'Get ready to witness Arijit Singh perform timeless hits like "Tum Hi Ho," "Kesariya," and "Channa Mereya" in an unforgettable live experience!',
        cta: "Buy Now",
        includeCities: ["jaipur"],
        link: {
            resource_type: "event",
            slug: "arijit-singh-live-in-concert-jaipur-jan25-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736687392/a2f302q5jdphvsbgktsi.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736687392/td7dl6kycur5at31bt1z.jpg",
        categoryIcon: <Music />,
        heading: "Yo Yo Live in Indore",
        subheading: "Bawaal Pakka Hai! Yo Yo Honey Singh is all set to take over your city!",
        caption: "If you’re not vibing with Yo Yo Honey Singh LIVE, wyd? Indore, lock in your plans, get your crew, and let’s TURN IT UP!",
        cta: "Buy Now",
        includeCities: ["indore"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-indore-mar8-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736687392/a2f302q5jdphvsbgktsi.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736687392/td7dl6kycur5at31bt1z.jpg",
        categoryIcon: <Music />,
        heading: "Millionaire Tour Live",
        subheading: "The OG Desi Kalakar is coming with his biggest-ever tour!",
        caption: "Lucknow, ho jao ready for a night of non-stop beats & swag! Honey Singh is bringing his all-time greatest hits LIVE! Grab your squad & book your tickets!",
        cta: "Buy Now",
        includeCities: ["lucknow"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-lucknow-feb28-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737271780/yiljlkdwj7kfir1lmcjv.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737271781/tlpa1m91vgnlp2rgiok6.jpg",
        categoryIcon: <Music />,
        heading: "Rockstar DSP Live!",
        subheading: "High energy hits await you!",
        caption: "An excellent dancer. A versatile composer. A chart-topper. DSP infuses his songs with irresistible dance spirits! Get ready to feel the vibes LIVE. ",
        cta: "Book Now",
        includeCities: ["vizag", "visakhapatnam"],
        link: {
            resource_type: "external",
            url: "rockstar-dsp-live-in-vizag-feb22-2025/event"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737963090/jdk6fgmnbgq95hl52yvf.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737965989/ohbvrzdnvpzbydn1l3a6.jpg",
        heading: "The pitch is calling you!",
        subheading: "Don’t get stumped, stay in the loop",
        caption: 'India vs England at VCA Stadium on Feb 6. Big shots, loud cheers, and YOU in the stands. Get notified when tickets go live.',
        cta: "Know More",
        includeCities: ["nagpur"],
        link: {
            resource_type: "event",
            slug: "idfc-first-bank-series-ind-v-eng-1st-odi-in-nagpur-cricket-sports-feb6-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
];
